import Contact from "./contact-form";
import Address from "./address-form";

export default class Franchise {
  constructor() {
    (this.id = null), (this.descr = null);
    this.name = "";
    this.status = null;
    this.type_id = null;
    this.business_name = null;
    this.address = new Address();
    this.business_activity = null;
    this.policy_ref_number = null;
    this.key_contact = new Contact();
    this.insurance_contact = new Contact();
    this.broker_contact = new Contact();
    this.brand_logo = null;
    this.brand_background_color = null;
    this.brand_text_color = null;
    this.comm_status = false;
    this.comm_reminder = null;
    this.franchisee_CSV = null;
    this.portal_link = null;
    this.block_policy_date = null;
    this.block_renewal_date = null;
  }
}

import authHeader from "./auth-header";
import axios from 'axios';

export default class common { 
  constructor(url_fragment) { 
    this.url = process.env.VUE_APP_API_BASE_URL + '/' + url_fragment
  }

  async create(obj,params = '') { 
    if(params) {
      params = '?' + new URLSearchParams(params)
    } 

    try { 
      let response = await axios.post(this.url + params, obj, {headers : authHeader()})
      return response
    }
    catch(err) { 
      return err.response
    }
  }
}
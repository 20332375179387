<template>
  <div class="pa-5">
    <div class="text-center">
      <h2 v-if="crud_state == '3'">Add Franchise/Scheme</h2>
      <h2 v-else-if="crud_state == '2'">Edit Franchise/Scheme</h2>
    </div>
    <div class="space-30"></div>
    <v-card v-if="loading">
      <v-skeleton-loader
        v-bind="$attrs"
        type="table-heading, list-item-two-line, image"
      ></v-skeleton-loader>
    </v-card>
    <v-stepper v-else v-model="currentStep">
      <v-stepper-header>
        <v-stepper-step
          :color="isFranchisor ? franchiseData.brand_text_color : 'primary'"
          :style="isFranchisor ? franchiseData.brand_background_color : ''"
          :rules="[(value) => stepsValidations.basicInfo]"
          :editable="item && item.id ? true : false"
          step="1"
        >
          Basic Information
          <small
            class="d-none d-lg-flex d-xl-none"
            v-if="!stepsValidations.basicInfo"
            >Enter Legal Entity Name & Activity</small
          >
        </v-stepper-step>

        <v-stepper-step
          v-if="type === 'franchise'"
          :color="isFranchisor ? franchiseData.brand_text_color : 'primary'"
          :style="isFranchisor ? franchiseData.brand_background_color : ''"
          :rules="[(value) => stepsValidations.key]"
          :editable="item && item.id ? true : false"
          step="2"
        >
          Key Contact
          <small class="d-none d-lg-flex d-xl-none" v-if="!stepsValidations.key"
            >Enter Contact Person</small
          >
        </v-stepper-step>

        <v-stepper-step
          v-if="type === 'franchise'"
          :color="isFranchisor ? franchiseData.brand_text_color : 'primary'"
          :style="isFranchisor ? franchiseData.brand_background_color : ''"
          :rules="[(value) => stepsValidations.insurer]"
          :editable="item && item.id ? true : false"
          step="3"
        >
          Insurance Contact
          <small
            class="d-none d-lg-flex d-xl-none"
            v-if="!stepsValidations.insurer"
            >Enter Contact Person</small
          >
        </v-stepper-step>

        <v-stepper-step
          v-if="type === 'franchise'"
          :color="isFranchisor ? franchiseData.brand_text_color : 'primary'"
          :style="isFranchisor ? franchiseData.brand_background_color : ''"
          :rules="[(value) => stepsValidations.broker]"
          :editable="item && item.id ? true : false"
          step="4"
        >
          Broker Contact
          <small
            class="d-none d-lg-flex d-xl-none"
            v-if="!stepsValidations.broker"
            >Enter Contact Person</small
          >
        </v-stepper-step>

        <v-stepper-step
          :color="isFranchisor ? franchiseData.brand_text_color : 'primary'"
          :style="isFranchisor ? franchiseData.brand_background_color : ''"
          :rules="[(value) => stepsValidations.comm_form]"
          :editable="item && item.id ? true : false"
          step="5"
        >
          Settings
          <small
            class="d-none d-lg-flex d-xl-none"
            v-if="!stepsValidations.comm_form"
            >Reminder Days Not valid</small
          >
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-form
          v-model="stepsValidations.basicInfo"
          ref="basicInfo"
          lazy-validation
        >
          <v-stepper-content id="basic-info" class="pa-0" step="1">
            <div class="pa-10">
              <p>Legal Entity Name</p>
              <v-text-field
                outlined
                v-model="item.name"
                :rules="[(v) => !!v || 'Legal Entity Name is required']"
                dense
                label="Enter Legal Entity Name"
              ></v-text-field>
              <p>Cluster Group</p>
              <v-autocomplete
                v-model="item.cluster_group_id"
                :loading="clusterLoading"
                :items="clusterItems"
                item-text="name"
                item-value="id"
                outlined
                dense
                label="Enter Cluster Group"
                autocomplete="nope"
              ></v-autocomplete>
              <v-row>
                <v-col cols="12" lg="6" md="6" class="col-x-margin-0">
                  <p>Business Activity</p>
                  <v-text-field
                    outlined
                    v-model="item.business_activity"
                    :rules="[(v) => !!v || 'Business Activity is required']"
                    dense
                    label="Enter Business Activity"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="6" md="6" class="col-x-margin-0">
                  <p>Formal Policy (Ref No)</p>
                  <v-text-field
                    outlined
                    v-model="item.policy_ref_number"
                    :rules="[(v) => !!v || 'Formal Policy Ref No. is required']"
                    dense
                    label="Enter Policy Ref no "
                    required
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" lg="6" md="6" class="col-x-margin-0">
                  <p>ABN</p>
                  <v-text-field
                    class="mb-5"
                    outlined
                    v-model="item.abn"
                    dense
                    label="Enter ABN"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="6" md="6" class="col-x-margin-0">
                  <p>WB Client Code</p>
                  <v-text-field
                    outlined
                    v-model="item.wb_client_code"
                    dense
                    label="Enter WB Client Code"
                  ></v-text-field>
                </v-col>
              </v-row>
              <AddressForm
                class="mb-5"
                :form="item.address ? item.address : blankAddress"
                :type="`business`"
                :title="'Address'"
                :isStateRequired="true"
              />

              <UserEditForm
                :item="item.user"
                v-if="isDataLoaded && isUserAdmin && type === 'franchise'"
              />
            </div>

            <v-footer paddless class="pa-10">
              <v-row class="justify-end ">
                <v-btn color="primary" @click="submitCurrentStep">
                  Continue
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>

                <v-btn text @click="backToFranchiseList">
                  Cancel
                </v-btn>
              </v-row>
            </v-footer>
          </v-stepper-content>
        </v-form>

        <v-stepper-content
          v-if="isDataLoaded && type === 'franchise'"
          id="key-contact"
          class="pa-0"
          step="2"
        >
          <ContactForm
            class="pa-10"
            ref="key_contact_form"
            :isValid="stepsValidations"
            :form="item.key_contact ? item.key_contact : blankContact"
            :type="`key`"
            :isAddress="false"
            :isPhone="false"
            :isFax="false"
            :isMobile="true"
            :isEmail="true"
            :isWebsite="true"
            isToBeNotified
            :formTitle="'Key Contact Information'"
          />
          <div class="space-30"></div>

          <v-footer paddless class="pa-7">
            <v-row>
              <v-col :cols="isSizeSmall ? 12 : 4">
                <v-btn
                  :block="isSizeSmall"
                  text
                  @click="navigateToPreviousStep"
                >
                  <v-icon>mdi-chevron-left</v-icon> Back</v-btn
                >
              </v-col>
              <v-col :cols="isSizeSmall ? 12 : 8">
                <v-row
                  class="justify-end pt-3"
                  :class="isSizeSmall ? 'px-10' : ''"
                >
                  <v-btn
                    color="primary"
                    :block="isSizeSmall"
                    @click="submitCurrentStep"
                  >
                    Continue
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>

                  <v-btn text :block="isSizeSmall" @click="backToFranchiseList">
                    Cancel
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-footer>
        </v-stepper-content>
        <v-stepper-content
          v-if="isDataLoaded && type === 'franchise'"
          id="insurance-contact"
          class="pa-0"
          step="3"
        >
          <ContactForm
            class="pa-10"
            :isValid="stepsValidations"
            :form="
              item.insurance_contact ? item.insurance_contact : blankContact
            "
            ref="insurance_contact_form"
            :type="`insurer`"
            :isAddress="true"
            :isPhone="true"
            :isEmail="true"
            :isWebsite="true"
            isToBeNotified
            :formTitle="'Insurance Contact Details'"
          />
          <div class="space-30"></div>

          <v-footer paddless class="pa-7">
            <v-row>
              <v-col :cols="isSizeSmall ? 12 : 4">
                <v-btn
                  :block="isSizeSmall"
                  text
                  @click="navigateToPreviousStep"
                >
                  <v-icon>mdi-chevron-left</v-icon> Back</v-btn
                >
              </v-col>
              <v-col :cols="isSizeSmall ? 12 : 8">
                <v-row
                  class="justify-end pt-3"
                  :class="isSizeSmall ? 'px-10' : ''"
                >
                  <v-btn
                    color="primary"
                    :block="isSizeSmall"
                    @click="submitCurrentStep"
                  >
                    Continue
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>

                  <v-btn text :block="isSizeSmall" @click="backToFranchiseList">
                    Cancel
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-footer>
        </v-stepper-content>

        <v-stepper-content
          id="broker-contact"
          class="pa-0"
          step="4"
          v-if="isDataLoaded && type === 'franchise'"
        >
          <ContactForm
            class="pa-10"
            :isValid="stepsValidations"
            :form="item.broker_contact ? item.broker_contact : blankContact"
            :type="`broker`"
            ref="broker_contact_form"
            :isAddress="true"
            :isPhone="true"
            :isFax="true"
            isToBeNotified
            :formTitle="'Broker Dedicated Contact'"
          />
          <div class="space-30"></div>
          <v-footer paddless class="pa-7">
            <v-row>
              <v-col :cols="isSizeSmall ? 12 : 4">
                <v-btn
                  :block="isSizeSmall"
                  text
                  @click="navigateToPreviousStep"
                >
                  <v-icon>mdi-chevron-left</v-icon> Back</v-btn
                >
              </v-col>
              <v-col :cols="isSizeSmall ? 12 : 8">
                <v-row
                  class="justify-end pt-3"
                  :class="isSizeSmall ? 'px-10' : ''"
                >
                  <v-btn
                    color="primary"
                    :block="isSizeSmall"
                    @click="submitCurrentStep"
                  >
                    Continue
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>

                  <v-btn text :block="isSizeSmall" @click="backToFranchiseList">
                    Cancel
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-footer>
        </v-stepper-content>

        <v-stepper-content id="settings" class="pa-0" step="5">
          <div class="pa-10">
            <h3>Branding</h3>
            <v-divider></v-divider>
            <div class="space-30"></div>
            <v-row>
              <v-col cols="12" lg="10">
                <p>Business Logo</p>
                <v-form v-model="brandImage.imgValid" ref="imgUpload">
                  <v-file-input
                    outlined
                    @change="validateImage($event)"
                    :rules="[brandImage.validateImg]"
                    v-model="brandImage.logo"
                    label="Upload Logo"
                    persistent-hint
                    hint="Logo should be 300 pixels width and 115 pixels height"
                    truncate-length="15"
                  ></v-file-input>
                  <div v-if="item.brand_logo" class="text-center">
                    <v-img :src="item.brand_logo" width="50%"></v-img>
                  </div>
                </v-form>
              </v-col>
              <v-col cols="12" lg="2">
                <v-btn
                  :color="
                    isFranchisor
                      ? franchiseData.brand_background_color
                      : 'success'
                  "
                  :style="
                    isFranchisor
                      ? { color: franchiseData.brand_text_color }
                      : ''
                  "
                  @click="uploadLogo"
                  class="mt-lg-10"
                >
                  <v-icon small left>mdi-check</v-icon> upload</v-btn
                >
              </v-col>
              <v-col cols="12" lg="6">
                <p>Background Color</p>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark v-bind="attrs" v-on="on" text>
                      <v-icon
                        v-if="item.brand_background_color"
                        class="mr-1"
                        :color="item.brand_background_color"
                        >mdi-circle</v-icon
                      >
                      <v-icon v-else class="mr-1">mdi-palette</v-icon>
                      Pick Color
                    </v-btn>
                  </template>
                  <v-color-picker
                    @update:color="setColor($event, 'brand_background_color')"
                    dot-size="25"
                    swatches-max-height="200"
                  ></v-color-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <p>Text Color</p>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark v-bind="attrs" v-on="on" text>
                      <v-icon
                        v-if="item.brand_text_color"
                        :color="item.brand_text_color"
                        class="mr-1"
                        >mdi-circle</v-icon
                      >
                      <v-icon v-else class="mr-1">mdi-palette</v-icon>
                      Pick Color
                    </v-btn>
                  </template>
                  <v-color-picker
                    @update:color="setColor($event, 'brand_text_color')"
                    dot-size="25"
                    swatches-max-height="200"
                  ></v-color-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" lg="6" md="6">
                <v-form v-model="stepsValidations.comm_form" ref="comm_status">
                  <v-switch
                    v-model="item.comm_status"
                    label="Turn on Communication"
                  ></v-switch>
                  <v-text-field
                    v-if="item.comm_status"
                    hint="Comma Separated Reminder Days"
                    label="Day Reminders"
                    :rules="[reminderDaysRule.required, reminderDaysRule.regex]"
                    v-model="item.comm_reminder"
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-form>
              </v-col>
              <v-col cols="12" lg="6" md="6">
                <h3>Import Franchisee/Sheme List</h3>
                <div class="space-20"></div>
                <v-row>
                  <v-col cols="9">
                    <v-file-input
                      :disabled="!id"
                      class="px-5"
                      outlined
                      dense
                      accept=".csv"
                      v-model="franchisee_CSV"
                      label="Upload CSV"
                      truncate-length="15"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12" lg="3" md="3">
                    <v-btn
                      :disabled="!id"
                      @click="uploadFranchisee"
                      :color="
                        isFranchisor
                          ? franchiseData.brand_background_color
                          : 'success'
                      "
                      :style="
                        isFranchisor
                          ? { color: franchiseData.brand_text_color }
                          : ''
                      "
                      ><v-icon small left>mdi-check</v-icon> Upload</v-btn
                    >
                  </v-col> </v-row
                >`
              </v-col>
            </v-row>

            <h3>Additional Settings</h3>
            <v-divider class="mt-2"></v-divider>
            <div class="space-30"></div>
            <v-row>
              <v-col cols="12" lg="6">
                <p class="px-5">Block All Policies</p>
                <v-menu
                  ref="menu"
                  v-model="all_policy_menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="item.block_policy_date"
                      label="Enter Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      clearable
                      class="px-5 mt-n2"
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="item.block_policy_date"
                    no-title
                    @input="all_policy_menu = false"
                  >
                    <v-spacer></v-spacer>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" lg="6">
                <p class="px-5">Block Renewal Process</p>
                <v-menu
                  ref="menu"
                  v-model="renewal_process_menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="item.block_renewal_date"
                      label="Enter Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      clearable
                      class="px-5 mt-n2"
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    no-title
                    v-model="item.block_renewal_date"
                    @input="renewal_process_menu = false"
                  >
                    <v-spacer></v-spacer>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </div>
          <div class="space-20"></div>

          <v-footer paddless class="pa-7">
            <v-row>
              <v-col :cols="isSizeSmall ? 12 : 4">
                <v-btn
                  :block="isSizeSmall"
                  text
                  @click="navigateToPreviousStep"
                >
                  <v-icon>mdi-chevron-left</v-icon> Back</v-btn
                >
              </v-col>
              <v-col :cols="isSizeSmall ? 12 : 8">
                <v-row
                  class="justify-end pt-3"
                  :class="isSizeSmall ? 'px-10' : ''"
                >
                  <v-btn
                    :color="
                      isFranchisor
                        ? franchiseData.brand_background_color
                        : 'success'
                    "
                    :block="isSizeSmall"
                    :style="
                      isFranchisor
                        ? { color: franchiseData.brand_text_color }
                        : ''
                    "
                    @click="validate"
                  >
                    <v-icon small left>mdi-check</v-icon>
                    Save
                  </v-btn>

                  <v-btn text :block="isSizeSmall" @click="backToFranchiseList">
                    Cancel
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-footer>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <Snackbar
      class="mb-10"
      v-if="snackbar.show"
      :show="snackbar.show"
      :text="snackbar.text"
      :color="snackbar.color"
    ></Snackbar>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ContactForm from "@/components/common/contact-form/ContactForm";
import AddressForm from "@/components/common/address-form/AddressForm";
import moment from "moment";
import Snackbar from "@/components/common/Snackbar";
import UserEditForm from "@/components/common/UserEditForm";
import Contact from "@/models/contact-form";
import Address from "@/models/address-form";
import { crud_state } from "@/lib/constants";
import BaseService from "@/services/base.service.js";
import CommonService from "@/services/common.service.js";
import Franchise from "@/models/franchise.js";
import { bus } from "@/main.js";
import _ from "lodash";
import FranchiseMixin from "@/mixins/franchise-style";

export default {
  name: "FranchiseEdit",
  components: {
    AddressForm,
    ContactForm,
    Snackbar,
    UserEditForm,
  },
  data() {
    return {
      stepsValidations: {},
      renewal_process_menu: false,
      all_policy_menu: false,
      snackbar: {},
      currentStep: "1",
      loading: false,
      item: {},
      isBrandLogoChanged: false,
      brandImage: {
        imgValid: false,
        validateImg: "",
        logo: null,
      },
      franchisee_CSV: null,
      clusterLoading: false,
      clusterItems: [],
      isDataLoaded: false,
      reminderDaysRule: {
        required: (v) =>
          (this.item.comm_status && !!v) || "Reminder Days is required",
        regex: (v) =>
          /^ *\d+ *(?:, *\d+ *)*$/.test(v) ||
          "Enter Comma Separated Reminder Days",
      },
    };
  },
  mixins: [FranchiseMixin],
  watch: {
    item: {
      handler() {
        if (this.id) {
          this.prepareJsonForExistingFranchise();
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters("auth", ["userRole"]),

    isUserAdmin() {
      return this.userRole.includes("ADMIN");
    },
    crud_state() {
      return this.$route.query.crud_state;
    },
    blankContact() {
      return new Contact();
    },
    blankAddress() {
      return new Address();
    },
    ImgTypesAllowed() {
      return process.env.VUE_APP_IMG_TYPE_ALLOWED;
    },
    isSizeSmall() {
      if (this.$vuetify.breakpoint.width < 800) return true;
      else return false;
    },
  },
  props: {
    id: String,
    type_id: Number,
    type: String,
  },
  mounted() {
    if (this.$route.query.franchiseSettings) {
      this.editFranchiseSettings();
    }
    this.getClusterGroups();
  },
  methods: {
    async getClusterGroups() {
      let service = new BaseService("lookups");
      let res = null;
      this.clusterLoading = true;
      try {
        res = await service.get_all({
          type_name: "Cluster Group",
        });
      } catch (e) {
        // nothing here
      } finally {
        this.clusterLoading = false;
      }
      this.clusterItems = res.data.results;
    },
    setContactInfo(form, type) {
      Object.keys(form).forEach((key) => {
        this.$set(this.item, `${type}_key`, form[key]);
      });
    },
    editFranchiseSettings() {
      this.item = this.franchiseData;
      this.currentStep = "5";
    },
    async uploadFranchisee() {
      if (this.item.id) {
        let formData = new FormData();
        formData.append(`franchisees_file`, this.franchisee_CSV);
        formData.append(`franchise_id`, this.item.id);
        let service = new CommonService("franchisees/upload");
        let res = await service.create(formData);
        if (res) {
          this.snackbar = {
            show: true,
            color: "success",
            text: `${res.data.success_count} Franchisee's has been successfully uploaded.`,
          };
          this.resetSnackbar();
        }
      }
    },
    async uploadLogo() {
      this.$refs.imgUpload.validate();
      if (this.brandImage.imgValid) {
        let formData = new FormData();
        formData.append(`file`, this.brandImage.logo);
        let service = new CommonService("static/upload");
        let params = { type: "franchise" };
        let res = await service.create(formData, params);
        if (res) {
          this.item.brand_logo = res.data.file_url;
          this.isBrandLogoChanged = true;
          this.$forceUpdate();
        }
      }
    },
    validateImage(val) {
      if (val && val.size > 500000) {
        this.brandImage.validateImg = "Image Size should not Exceeds 500kb";
        this.brandImage.imgValid = false;
      } else {
        this.brandImage.validateImg = true;
        this.brandImage.imgValid = true;
      }
    },
    setColor(value, type) {
      this.$set(this.item, type, value.hex);
    },
    submitCurrentStep() {
      let validated;
      switch (this.currentStep) {
        case "1":
          this.stepsValidations.basicInfo = false;
          validated = this.$refs.basicInfo.validate();
          if (validated) {
            this.stepsValidations.basicInfo = true;
            this.navigateToNextStep();
          }
          break;
        case "2":
          this.stepsValidations.key = false;
          validated = this.$refs["key_contact_form"].$refs[
            `contact-form`
          ].validate();
          if (validated) {
            this.stepsValidations.key = true;
            this.navigateToNextStep();
          }
          break;
        case "3":
          this.stepsValidations.insurer = false;
          validated = this.$refs["insurance_contact_form"].$refs[
            `contact-form`
          ].validate();
          if (validated) {
            this.stepsValidations.insurer = true;
            this.navigateToNextStep();
          }
          break;
        case "4":
          this.stepsValidations.broker = false;
          validated = this.$refs["broker_contact_form"].$refs[
            `contact-form`
          ].validate();
          if (validated) {
            this.stepsValidations.broker = true;
            this.navigateToNextStep();
          }
          break;
      }
    },
    async get_item(id) {
      this.loading = true;
      let service = new BaseService("franchises");
      let res = await service.get_one(id);
      this.loading = false;
      return res.data;
    },
    navigateToNextStep() {
      let stepNo = parseInt(this.currentStep);
      if (this.type === "franchise") {
        stepNo += 1;
      } else {
        stepNo = stepNo + 4;
      }
      this.currentStep = `${stepNo}`;
    },
    navigateToPreviousStep() {
      let stepNo = parseInt(this.currentStep);
      stepNo -= 1;
      this.currentStep = `${stepNo}`;
    },
    resetSnackbar() {
      setTimeout(() => {
        this.snackbar = {};
      }, 2000);
    },
    backToFranchiseList() {
      if (this.$route.query.franchiseSettings) {
        this.$router.push(`/user`);
      } else {
        this.currentStep = "1";
        this.$router.push("/franchise/list");
      }
    },
    async saveFranchise() {
      this.loading = true;
      let service = new BaseService("franchises");
      let res = null;
      if (this.item.id && !this.isBrandLogoChanged) {
        this.$delete(this.item, "brand_logo");
      }

      let franchiseJson = _.cloneDeep(this.item);
      franchiseJson = this.prepareJsonForSaving(franchiseJson);
      // franchiseJson.user = { ...this.$store.state.auth.user };

      if (this.crud_state == crud_state.NEW) {
        res = await service.create(franchiseJson);
      } else {
        res = await service.update(franchiseJson);
      }
      this.loading = false;

      if (res.status == 401) {
        this.handleLogout();
      } else if (res.status == 200 || res.status == 201) {
        bus.$emit("edit_save", { msg: "Saved successfully", data: res.data });
        if (this.$route.query.franchiseSettings) {
          this.$router.push("/user");
        } else {
          this.$router.push(`/franchise/list/${this.type}`);
        }
      } else {
        bus.$emit("edit_error", { msg: "An error occurred, could not save" });
      }
    },

    prepareJsonForExistingFranchise() {
      if (this.item.block_policy_date) {
        this.item.block_policy_date = this.formatDate(
          this.item.block_policy_date
        );
      }

      if (this.item.block_renewal_date) {
        this.item.block_renewal_date = this.formatDate(
          this.item.block_renewal_date
        );
      }

      if (!this.item.address) {
        this.item.address = this.blankAddress;
      }

      // Making Object from null if address particulars is coming as null in an existing Franchise
      if (this.item) {
        const allContactTypes = [
          `address`,
          `key_contact`,
          `insurance_contact`,
          `broker_contact`,
        ];
        allContactTypes.map((contact) => {
          let contactType =
            contact === "address"
              ? this.item.address
              : this.item[contact]?.address;
          if (contactType) {
            Object.keys(contactType).forEach((key) => {
              if (
                contactType[key] === null &&
                (key == "country" || key == "state" || key == "city")
              ) {
                contactType[key] = {};
              }
            });
          } else {
            this.item[contact] = this.blankContact;
          }
        });
      }
    },
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    prepareJsonForSaving(json) {
      // Cleaning all the address if it is blank and preparing props for the API
      const allContactTypes = [
        `address`,
        `key_contact`,
        `insurance_contact`,
        `broker_contact`,
      ];
      allContactTypes.map((contact) => {
        let contactType =
          contact === "address" ? json.address : json[contact].address;
        if (contactType) {
          Object.keys(contactType).forEach((key) => {
            if (
              contactType[key] !== null &&
              typeof contactType[key] == "object"
            ) {
              let length = Object.keys(contactType[key]).length;
              if (length < 1) {
                contactType[key] = null;
              } else {
                let id = contactType[key].id;
                let oldKey = key;
                let newKey = key + "_id";
                contactType[newKey] = id;
                delete contactType[oldKey];
              }
            }
          });
        }
      });

      // Clearning all the other contact info
      allContactTypes.map((contact) => {
        let contactType = json[contact];
        if (contactType) {
          Object.keys(contactType).forEach((key) => {
            if (
              contactType[key] !== null &&
              typeof contactType[key] == "object"
            ) {
              let length = Object.keys(contactType[key]).length;
              if (length < 1) {
                contactType[key] = null;
              }
            }
          });
        }
      });

      return json;
    },
    validate() {
      this.$refs[`basicInfo`].validate();

      if (this.type === "franshise") {
        this.$refs["key_contact_form"].$refs[`contact-form`].validate();
        this.$refs["insurance_contact_form"].$refs[`contact-form`].validate();
        this.$refs["broker_contact_form"].$refs[`contact-form`].validate();
      }

      this.$refs[`comm_status`].validate();

      this.$nextTick(() => {
        let notValidated = Object.keys(this.stepsValidations).filter((key) => {
          return this.stepsValidations[key] === false;
        });

        if (notValidated && notValidated.length > 0) {
          this.snackbar = {
            show: true,
            text: `Please Fill All the required Fields in the steps`,
            color: `red`,
          };
          this.resetSnackbar();
        } else this.saveFranchise();
      });
    },
  },
  async created() {
    if (this.id) {
      this.item = await this.get_item(this.id);
    } else {
      this.item = new Franchise();
      this.item.status = true;
      this.item.type_id = this.type_id;
    }
    this.item.user = this.item.user ?? {};
    this.item.user.user_role = this.item.user.user_role ?? 200;
    this.isDataLoaded = true;
  },
};
</script>
<style scoped>
.col-x-margin-0 {
  padding: 0 12px;
}
.v-stepper__step {
  padding: 24px 12px;
}
.v-stepper__header {
  flex-wrap: unset;
  justify-content: space-around;
}
@media screen and (max-width: 600px) {
  .v-stepper__step {
    padding: 0px !important;
  }
}
</style>
